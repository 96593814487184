<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Mymovingway
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{$t('Forgot Password?')}} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t("Enter your email and we'll send you instructions to reset your password")}}
          </b-card-text>

          <!-- form -->
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <PrecheckPersonEmail :personData="personData" :isNext="isSubmit" :$v="$v"/>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{$t('Send reset link')}}
              </b-button>
            </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{$t('Back to login')}}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { email, required } from 'vuelidate/lib/validators'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BImg, BLink, BRow, } from 'bootstrap-vue'
// import { required, email } from '@validations'
import store from '@/store'
import PrecheckPersonEmail from '@/components/PrecheckPersonEmail/PrecheckPersonEmail';

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    // ValidationProvider,
    // ValidationObserver,
    PrecheckPersonEmail
  },
  data() {
    return {
      personData: {
        email: '',
      },
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      isSubmit: false
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      if(this.$v.$invalid === false){
        this.$database.AccountService.forgot_password({ email: this.personData.email })
          .then(res => {
            console.log(res);
            if(res.IsSuccess === true || res.IsSuccess === 1){
              this.$router.push({name: 'auth-login'})
              this.$functions.Messages.success('Mail Sent, Check Your Mail Inbox')
            } else {
              this.$functions.Messages.error('Mail Sending Process Failed')
            }
          })
      } else {
        this.isSubmit = true
      }

    },
  },
  validations: {
    personData: {
      email: {
        required,
        email,
      },
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
